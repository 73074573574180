import React from "react";
import group from "../images/ceed/group.jpg";
import Layout from "../components/layout";
import { Link } from "gatsby";

const AboutPage = () => (
  <Layout>
    <section id="section-page2" className="section general-page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8  text-center">
            <div className="page-heading">
              <h2 className="display-4">About Us</h2>

              <ul className="list-unstyled list-inline">
                <li className="list-inline-item"><Link to={"/"}>Home </Link></li>
                <li className="list-inline-item"><a>/</a></li>
                <li className="list-inline-item"><a href="#" className="text-muted">About Us</a></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about-page" className="section about-page">

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle text-primary">About CAP</h6>
              <h2 className="display-4">Promoting Entrepreneurship</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 cap-group">
            <img  src={group} alt={"CAP-group"}/>
            <h6 class="image-title">Team CAP with Mr Ramanan Ramanathan (Mission Director Atal Innovation Mission - Niti Aayog - Govt. of India)</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p>CAP - CEED ACCELERATOR PROGRAM is the most stellar initiative of Chitkara University to promote
              entrepreneurship in the North India. With a core aim to build campus startups and market ready
              enterprises,
              CAP is supported and run by Industry entrepreneurs, academicians and Angel Investors.</p>
            <p>The 48-weeks long program is a credit-based course for final UG & PG students and alumni of Chitkara
              University. The course takes a deep dive into key topics as Data understanding and analytics, Design
              thinking, Digital and offline marketing, Idea validation and feasibility studies, Content and UI/UX, live
              exposure to work as a shadow entrepreneur, working in an existing startup environment, profitability and
              analysis, IPR and Prototyping, Brand building and communications, personality and grooming, leadership and
              management and all aspects to launch a company.</p>
            <p>The Cohort selection is done through an open application and interview process. Students with
              an entrepreneurial bent of mind, with early stage startup ideas are given due consideration. The first
              Cohort
              with 20 aspiring entrepreneurs took off on July 10th 2019 at Explore Hub with an inaugural lecture from
              CAP
              Director and architect Mr. Sumeer Walia. Natio Cultus- Bangalore and Chandigarh Angels Network are the key
              partners of CAP, to steer this focussed mission of Chitkara University's Centre for Entrepreneurship and
              Education Development.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="actions">
              <a href="https://bit.ly/2HooMXE" className="btn btn-hero btn-rounded" target="_blank">Join The Program</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default AboutPage;
